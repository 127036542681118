import React from 'react'
import { graphql } from 'gatsby'
import LayoutTwo from '../components/layoutTwo'
import Seo from "../components/seo"
import BreadcrumbModule from '../components/BreadcrumbModule/BreadcrumbModule'
import ListingIntro from '../components/ListingIntro/ListingIntro'
import NewsLanding from '../components/NewsLanding/NewsLanding'
import SellLetHome from '../components/SellLetHome/SellLetHome'

const News = ({ data }) => {
    const PageData = data?.strapiPage
    const allNews = data?.allStrapiBlog.edges
    return (
        <LayoutTwo popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top"></div>
            <BreadcrumbModule
                parentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
                parentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
                title={PageData.title}
            />
            {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "blog" && <div className='layout-grey-gradient-bg'>
                            {module.content && <ListingIntro {...module} />}
                            <NewsLanding allNews={allNews} />
                        </div>}


                        {module.strapi_component === "page-modules.plain-content" && module.layout === "bg_color_center_align" && <SellLetHome {...module} />}
                    </>
                )
            }
            )}
        </LayoutTwo>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default News


export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        add_page_modules {
              ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
                  ...GlobalModuleFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                  ...PlainContentFragment
              }
        }
    }
    allStrapiBlog(filter: {publish: {eq: true}}, sort: {fields: date, order: DESC}) {
        edges {
          node {
            date(formatString: "DD MMM, yyyy")
            title
            slug
            strapi_id
            tile_image {
              alternativeText
              url
            }
            imagetransforms {
              tile_image_Transforms
            }
          }
        }
    }
  }
`