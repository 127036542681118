import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './assets/styles/_index.scss'
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule");

const ListingIntro = (props) => {
    return (
        <div className='listing-intro-wrapper'>
            <Container>
                <Row>
                    <Col>
                        {props.content ? <ContentModule Content={props.content?.data?.content} /> : '' }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ListingIntro