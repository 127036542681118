import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import loadable from '@loadable/component';
import './assets/styles/_index.scss'

const NewsCard = loadable(() => import("../../components/NewsCard/NewsCard"));
const LandingPagePagination = loadable(() => import("../InnerPagination/LandingPagePagination"));

const { usePagination } = require("@starberry/gatsby-theme-utils/Hooks/UsePagination")


const NewsLanding = ({allNews}) => {

    // for pagination
    const itemsPerPage = 16
    const { currentItems, currentPage, setCurrentPage } = usePagination({
        items: allNews,
        itemsPerPage,
    })
    // for pagination

    return (
        <div className='news-landing-section'>
            <Container>
                <Row>
                    <Col>
                        <div className='news-landing-wrapper'>
                            {currentItems && currentItems.map(({node}, index) => {
                                return (
                                    <div key={index}>
                                        <NewsCard {...node} />
                                    </div>
                                )
                            })}
                        </div>
                        <LandingPagePagination
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            totalItems={allNews?.length}
                            setCurrentPage={setCurrentPage}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default NewsLanding